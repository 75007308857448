// POINTS BADGE

.points-badge {
  display: inline-block;
  border-radius: 6px;
  padding: 4px 8px;
  background: $color-blue-950;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
  color: white;
}

.points-badge img {
  vertical-align: middle;
  margin-right: 6px;
}

.points-badge span {
  vertical-align: middle;
}

ion-button.main-button {
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  line-height: $line-height-18px;
  text-align: left;
  margin: 0;
  --padding-top: 1.6rem;
  --padding-bottom: 1.6rem;
  --padding-start: 1rem;
  --padding-end: 1rem;
  --background: linear-gradient(225deg, #2A31D3 0%, #494FDB 100%);
  --box-shadow: 0px 10px 10px 0px #00000017, 0px 10px 10px 0px #0000000D, 0px 10px 10px 0px #00000003;
}

ion-button.main-button[fill="outline"] {
  --border-color: #{$color-blue-950};
  --color: #{$color-blue-950};
  --background: white;
}

