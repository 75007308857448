ion-tab-bar {

  ion-tab-button {
    color: $shade-tertiary;
    font-size: $font-size-sm;
    --padding-end: 3px;
    --padding-start: 3px;
  }

  .tab-selected {
    color: $shade-primary;
  }
}

