// WARNING: There is a helper ShadowRooHelper.applyIonSelectStyles() to style the elements without "part" inside the "shadow-root"

ion-select {
  //--background: white;
}

ion-select::part(text) {
  color: $font-color-primary;
}

.select-fill-outline, .select-fill-outline.select-expanded, .select-fill-outline.ion-focused {
  border: solid 1px #E1E1E3;
  border-radius: 10px;
}

ion-select::part(icon) {
  color: $shade-tertiary;
  opacity: 1;
}
