@font-face {
  font-family: 'Satoshi';
  font-weight: 300;
  font-style: normal;
  src: url('../assets/fonts/Satoshi-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/Satoshi-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/Satoshi-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: 600;
  font-style: normal;
  src: url('../assets/fonts/Satoshi-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/Satoshi-Bold.ttf') format('truetype');
}

//@font-face {
//  font-family: 'BrandonGrotesque';
//  font-weight: 400;
//  font-style: normal;
//  src: url('../assets/fonts/BrandonGrotesque-Regular.ttf') format('truetype');
//}
//
//@font-face {
//  font-family: 'BrandonGrotesque';
//  font-weight: 500;
//  font-style: normal;
//  src: url('../assets/fonts/BrandonGrotesque-Medium.ttf') format('truetype');
//}
//
//@font-face {
//  font-family: 'BrandonGrotesque';
//  font-weight: 600;
//  font-style: normal;
//  src: url('../assets/fonts/BrandonGrotesque-Bold.ttf') format('truetype');
//}

//@font-face {
//  font-family: 'HeldaneDisplay';
//  font-weight: 400;
//  font-style: normal;
//  src: url('../assets/fonts/HeldaneDisplay-Regular.otf') format('truetype');
//}
//
//@font-face {
//  font-family: 'HeldaneDisplay';
//  font-weight: 500;
//  font-style: normal;
//  src: url('../assets/fonts/HeldaneDisplay-Medium.otf') format('truetype');
//}
//
//@font-face {
//  font-family: 'HeldaneDisplay';
//  font-weight: 600;
//  font-style: normal;
//  src: url('../assets/fonts/HeldaneDisplay-Bold.otf') format('truetype');
//}

:root {
  --ion-font-family: 'Satoshi';
  --ion-text-color: #{$font-color-primary};
  --ion-color-success: #{$col-success};
  --ion-background-color: #{$shade-primary-lv0};
  --ion-tab-bar-background: #{$white};
}

html {
  font-size: 100%;
}

body {
  font-size: $font-size-md;
}

p {
  font-size: $font-size-md;
}

p.subtitle {
  text-align: center;
  font-weight: $font-weight-medium;
  width: 300px;
  margin: 2rem auto 2rem auto;
}

h1 {
  font-size: $font-size-32px;
}

h2 {
  font-size: $font-size-xl;
}

h3 {
  font-size: $font-size-mdx;
}

h1, h2, h3 {
  text-align: center;
  font-weight: $font-weight-bold;
}

.alt-text {
  color: $font-color-secondary;
}

.brand-text-secondary {
  color: $brand-secondary;
}

.text-bold {
  font-weight: $font-weight-semibold !important;
}

.text-weight-regular {
  font-weight: $font-weight-normal !important;
}

.text-small {
  font-size: $font-size-sm !important;
}

.text-without-ellipsis {
  white-space: normal;
}

.mvp-notice {
  color: $danger-font-color;
  text-align: center;
}

.input-warning-text {
  color: $warning-font-color;
  font-size: $font-size-sm;
  line-height: 1.8rem;
  margin-top: 0.8rem;
}

.all-uppercase {
  text-transform: uppercase;

  ::placeholder {
    text-transform: initial;
  }
}

.refresher-refreshing-icon {
  display: none !important;
}
