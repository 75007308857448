ion-header ion-toolbar ion-button {
  --color: $font-color-primary;
  text-transform: uppercase;
}

ion-header, ion-header.header-md {
  box-shadow: none;
}

ion-header ion-toolbar {
  //--background: rgba(255, 255, 255, .8);
  //backdrop-filter: blur(6px);
  //-webkit-backdrop-filter: blur(6px);
}

ion-header.header-ios:not(.wizard-header) {
  //-webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  //box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

ion-header {
  ion-toolbar.ios {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}


ion-header.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

ion-toolbar {
  --background: #FFF;

  ion-title.wide-absolute-center {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 0 60px;
    left: 0;
    opacity: 1 !important;
  }

  ion-title.absolute-center {
    position: absolute;
    width: calc(100% - 140px);
    height: 100%;
    top: 0;
    margin: 0 70px;
    left: 0;
  }

  &.ios ion-title.absolute-center {
    padding-left: 0;
    padding-right: 0;
  }

  ion-title img {
    position: relative;
    top: 3px;
  }

  ion-button {
    //--border-radius: 5px;
  }

  .sc-ion-buttons-md-s ion-button:not(.button-round) {
    --border-radius: 3px;
    --border-width: 1px;
  }
}
