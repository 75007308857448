@import 'src/theme-rs/variables';

.form-row {
  margin-bottom: 1.6rem;
}

.form-field {
}

.form-field .label {
  font-weight: $font-weight-medium;
  display: block;
  margin-bottom: 0.5rem;
}

.form-field .input-text {
  border: 1px solid $color-border-medium;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.form-field .input-text ion-input {
  --background: #fff;
  --color: #{$color-text-dark};
  --border-radius: 8px;
  --padding-bottom: 10px;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 10px;
  --highlight-color-invalid: #{$color-text-error}
}

.form-field .input-text.disabled {
  background: $color-black-50;
  color: $color-dark-medium;
}

.form-field .input-text .disabled-field {
  padding: 10px;
}

.inline-errors-wrapper {
  margin-top: 0.2rem;
}

.inline-error {
  color: $color-text-error;
  font-size: $font-size-xxs;
  font-weight: $font-weight-medium;
  line-height: $line-height-14px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
}

.inline-error::before {
  content: '';
  background: url('/assets/icon/alert.svg') no-repeat center center;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
