.spinner {
  width: 36px;
  height: 36px;
  //border: 3px solid $shade-primary;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  background-image: url('/assets/images/spinner-s.svg');
  background-position: 50% 50%;
  background-size: cover;

  &.negative { // over dark backgrounds
    background-image: url('/assets/images/spinner-s_negative.svg');
  }
}

//.spinner::after {
//  content: '';
//  box-sizing: border-box;
//  position: absolute;
//  left: 50%;
//  top: 50%;
//  transform: translate(-50%, -50%);
//  width: 40px;
//  height: 40px;
//  border-radius: 50%;
//  border: 3px solid transparent;
//  border-bottom-color: $shade-secondary;
//}

.spinner.medium {
  width: 36px;
  height: 36px;
}

//
//.spinner.medium::after {
//  width: 30px;
//  height: 30px;
//}

.spinner.small {
  width: 24px;
  height: 24px;
}

//
//.spinner.small::after {
//  width: 20px;
//  height: 20px;
//}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.inline-spinner-wrapper {
  position: absolute;
  right: -4px;
  top: -9px;
}
