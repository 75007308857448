ion-button {
  font-size: $font-size-md;
  text-transform: uppercase;
  //margin-left: 1rem;
  //margin-right: 1rem;
  --box-shadow: none;
  font-weight: $font-weight-bold;

  &::part(native) {
    min-height: 45px;
  }

  .spinner {
    // over dark backgrounds
    filter: brightness(100%) contrast(200%);
  }
}
