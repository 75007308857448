// MEDIA QUERIES BREAKPOINTS
// --------------------------------------------------
// Define the breakpoints at which your layout will change, adapting to different screen sizes.


// Default css rules are for the smallest screens, so no breakpoint needed (i.e. 320px like iPhone 5/SE, Samsung S9+, Samsung Xcover 5…)

// Then, first breakpoint is for normal smartphones (iPhone XR, iPhone 15, Samsung S20, Samsung A13…) which may need bigger font sizes, margins, paddings…
$screen-xxs: 360px !default;
$screen-xxs-min: $screen-xxs !default;
$screen-small-phone: $screen-xxs-min !default;

// Larger smartphones or small tablets (Nexus 7, Galaxy Tab S4…) which may need more items per row, bigger font sizes, margins, paddings…
$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;

// Normal tablets (like iPad, iPad Air) which may need more items per row, bigger font sizes, margins, paddings…
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;

// Desktop small screen (iPad Pro, Computer with 1024 monitor)
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;

// Desktop large screen / wide desktop (1280 modern monitor)
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

// COLOR VARIABLES
// --------------------------------------------------
// Named colors makes it easier to use a defined color palette.
// Don't use them directly in css classes, use the context specific variables instead.

$soft-black: #232328;
$dark-gray: #4a4a4a;
$light-gray: #9b9b9b;
$gray: #e8e8e8;
$lighter-gray: #f8f8f7;
$lighter-gray: #f8f8f7;
$white: #ffffff;
$green: #78BC62;
$lightning-yellow: #FFA800;
$red: #E91E63;

$brand-primary: #060338;
$brand-secondary: #ce107f;
$col-success: #0ab39c;
$col-danger: #f06548;
$col-warning: #f7b84b;

$dark-blueish: #878a99;
$blueish: #89a5b5;
$electric-blue: #0284ff;

// CONTEXT/HIERARCHY VARIABLES
// --------------------------------------------------
// Used to respect the hierarchy of styles through all the application.
// Use then in the CSS classes.

$shade-primary: #1A1A1A;
$shade-secondary: #ef700a;
$shade-tertiary: #808080;

$shade-primary-lv0: #fff; // OPA Far backgrounds
$shade-primary-lv1: #19232d; // Secondary boxes backgrounds
$shade-primary-lv1-border: #313b45; // Elements: border for lv1
$shade-primary-lv2: $brand-primary; // Elements: shade for clickable big elements
$shade-primary-lv2-border: #d8d8d8; // OPA Elements: border for lv2
$shade-primary-lv3: #4e6481; // Elements: secondary boxes without links
$shade-primary-lv4: #606a7b; // Elements: link, icons, link texts
$shade-primary-lv5: #0ab39c; // Elements: buttons sm boxes
$shade-primary-content-boxes: #f3f3f9; // Backgrounds for primary content boxes
$shade-card-background: #fff; // Background for normal cards
$shade-not-planned-card-background: #FFFEF2; // Background for not-planned task cards
$shade-based-desaturated: #000; // based on brand color but desaturated (used in icons buttons)
$shade-input: #e9e9ef;
$shade-delicate-border: #f0f0f0;
$shade-secondary-text-lv1: $brand-secondary;

$closed-shade-color: #BCBCBC;
$closed-font-color: white;

$disabled-shade-color: #efefef;
$disabled-font-color: #bcbcbc;
$disabled-border-color: #e3e3e3;

$danger-background-color: #FDE8E4;
$danger-border-color: #FFDFA9;
$danger-font-color: #B44C36;
$danger-font-color-on-doc-cards: white;
$danger-shade-color: #F06548;

$warning-border-color: #FFDFA9;
$warning-font-color: #B98A38;
$warning-font-color-on-doc-cards: white;
$warning-shade-color: #F7B84B;
$warning-background-color: #FEF4E4;

$in-progress-shade-color: #F5A623;
$in-progress-font-color: white;

$btn-primary-shade: $electric-blue;
$btn-secondary-shade: $brand-secondary;
$box-shadow-regular: rgb(0 0 0 / 25%) 0px 0px 4px 0px;

$problem-color: $red;
$success-color: $green;
$warning-color: $lightning-yellow;


// OVERRIDE COLORS USED AS IONIC'S 'COLOR' ATTRIBUTE
// --------------------------------------------------
:root {
  --ion-color-dark: #{$shade-primary};
  --ion-color-dark-rgb: 26, 26, 26;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #{$shade-primary};
  --ion-color-dark-tint: #959595;
  // danger2
  --ion-color-danger2: #FF3E4E;
  --ion-color-danger2-rgb: 255,62,78;
  --ion-color-danger2-contrast: #000000;
  --ion-color-danger2-contrast-rgb: 0,0,0;
  --ion-color-danger2-shade: #e03745;
  --ion-color-danger2-tint: #ff5160;
}

.ion-color-dark {
  --ion-color-base: var(--ion-color-dark) !important;
  --ion-color-base-rgb: var(--ion-color-dark-rgb) !important;
  --ion-color-contrast: var(--ion-color-dark-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-dark-shade) !important;
  --ion-color-tint: var(--ion-color-dark-tint) !important;
}

// danger2
.ion-color-danger2 {
  --ion-color-base: var(--ion-color-danger2);
  --ion-color-base-rgb: var(--ion-color-danger2-rgb);
  --ion-color-contrast: var(--ion-color-danger2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger2-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger2-shade);
  --ion-color-tint: var(--ion-color-danger2-tint);
}

// TYPOGRAPHY VARIABLES
// --------------------------------------------------
// Useful to give the app a coherence through all the screens

// the relation factor is 1,6 (or inverted: 62,5)
$font-size-10px: calc(1rem * 0.625); // 10px
$font-size-xxs: calc(1.2rem * 0.625); // 12px
$font-size-xs: calc(1.3rem * 0.625); // 13px
$font-size-sm: calc(1.4rem * 0.625); // 14px
$font-size-md: calc(1.6rem * 0.625); // 16px
$font-size-mdx: calc(1.8rem * 0.625); // 18px
$font-size-lg: calc(2.0rem * 0.625); // 20px
$font-size-22px: calc(2.2rem * 0.625); // 22px
$font-size-xl: calc(2.4rem * 0.625); // 24px
$font-size-32px: calc(3.2rem * 0.625); // 32px
$font-size-xlx: calc(2.4rem * 0.625); // 36px
$font-size-xxl: calc(4.8rem * 0.625); // 48px

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$line-height-12px: calc(1.2rem * 0.625);
$line-height-14px: calc(1.4rem * 0.625);
$line-height-16px: calc(1.6rem * 0.625);
$line-height-18px: calc(1.8rem * 0.625);
$line-height-20px: calc(2.0rem * 0.625);
$line-height-24px: calc(2.4rem * 0.625);
$line-height-25px: calc(2.5rem * 0.625);
$line-height-28px: calc(2.8rem * 0.625);
$line-height-36px: calc(3.6rem * 0.625);

$font-color-primary: #171717; // Primary text in the page
$font-color-secondary: $dark-blueish; // Secondary texts (complementing a primary text)
$font-color-tertiary: $blueish; // Tertiary texts, usually independent but below secondary in hierarchy
$font-color-links: $electric-blue;
$font-color-links-secondary: $dark-blueish;

$alt-text-color: #878a99;
$btn-as-link-text-color: $electric-blue;


$color-link: #0066FF;
$color-success: #2DCCD3;
$color-success-50: #2DCCD305;
$color-success-200: #92E5E8;
$color-text-error: #FF3E4E;
$color-text-error-50: #FFF6F6;
$color-text-dark: #171717;
$color-dark-medium: #949496;
$color-border-medium: #E1E1E3;
$color-black-50: #F7F7F8;
$color-black-100: #EEEEF1;
$color-black-400: #BBBBBC;
$color-black-600: #6A6A6B;
$color-black-700: #4F4F50;
$color-yellow-300: #FEF0BD;
$color-yellow-400: #FEEDAE;
$color-yellow-500: #FEE99F;
$color-yellow-600: #FEE590;
$color-pink-50: #FFE6F1;
$color-pink-100: #FFE1EE;
$color-pink-400: #FFC2DE;
$color-pink-600: #FFAED3;
$color-pink-950: #FF82BB;
$color-blue-50: #E8E9FA;
$color-blue-200: #C8CAF4;
$color-blue-600: #7378E3;
$color-blue-900: #333AD6;
$color-blue-950: #2A31D3;

$color-gradient-start: #FFF9B2;
$color-gradient-end: #F39DB7;

$color-extra-debug: #F7B84B;


// LAYOUT VARIABLES
// --------------------------------------------------
// Layout variables are useful to be able to easily change general app layout properties

$content-left-margin: 1rem;
$content-right-margin: 1rem;
