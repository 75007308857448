
ion-modal.rounded-borders {
  --border-radius: 20px;
}

ion-modal.top-rounded-borders {
  --border-radius: 20px 20px 0 0;
}


ion-modal.quiz-modal {
  --border-radius: 20px;
  top: 20px;
}

ion-modal.prize-qr-modal {
  --border-radius: 20px 20px 0 0;
}

ion-modal.prize-qr-modal::part(content),
ion-modal.start-experience-language-modal::part(content) {
  margin-top: 20px;
  height: calc(100% - 20px)
}


ion-modal.close-quiz {
  --width: 330px;
  --height: 550px;
  --backdrop-opacity: 0.5 !important;
}

ion-modal.prize-modal {
  --width: 330px;
  --height: 330px;
  --backdrop-opacity: 0.5 !important;
  --border-radius: 12px;
}
